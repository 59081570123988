import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TyperService {

  // BeahviorSubject is a variant of Subject and Subject is a sub-type of Observable that requires 
  // an initial value and emits its current
  // value whenever it is subscribed to

  // BeahviorSubject ha bisogno di un valore iniziale in quanto 
  // deve restituire sempre un valore alla sottoscrizione anche senza un next()
  public isLoading = new BehaviorSubject(false);
  constructor() { }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SpeechToTextResponse } from '../models/speech-to-text-response'
import { DFResponse } from '../models/dfresponse';

const CONN_CONFIGURATION = Object({
  server_address: 'https://pcf-server.alecsandria.it',
  /* server_address: 'http://localhost:8357', */
});

const SERVER_API = Object({
  send_message: 'api/textmessage',   // used to send message to server which will forward it to dialogflow
  audio_message: 'api/audiomessage'
});

@Injectable({
  providedIn: 'root'
})
export class DialogflowService {

  constructor(private http: HttpClient) {}

  private apiUrlBuilder(serverApi: string): string {
    return CONN_CONFIGURATION.server_address + '/' + serverApi;
    //return CONN_CONFIGURATION.server_address + ':' + CONN_CONFIGURATION.server_port + '/' + serverApi;
  }

  public getTextFromSpeech(data: string): Observable<SpeechToTextResponse> {
    return this.http.post<SpeechToTextResponse>(this.apiUrlBuilder(SERVER_API.audio_message), { message: data });
  }

  public send(text: string, sessionId: string, language: string, env: string, authPiva: string, authIdMin: string): Observable<DFResponse> {
    const req = {
      text: text,
      sessionId: sessionId,
      language: language,
      env: env,
      authPiva : authPiva,
      authIdMin : authIdMin
    };
    
    return this.http.post<DFResponse>(this.apiUrlBuilder(SERVER_API.send_message), req);
  }

}

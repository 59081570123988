import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbThemeModule } from './nebular/theme.module';
import { NbChatModule } from './nebular/components/chat/chat.module';
import { NbLayoutModule } from './nebular/components/layout/layout.module';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { ChatCustomMessageComponent } from './chattest/chat-custom-message.component';
import { SafeHtml } from './utils/safe-html';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TyperComponent } from './typer/typer/typer.component';
import { TyperInterceptor } from './interceptor/typer.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ChatbotComponent,
    ChatCustomMessageComponent,
    SafeHtml,
    TyperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbChatModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [ 
    { provide: HTTP_INTERCEPTORS, useClass: TyperInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

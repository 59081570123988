import { Component, OnInit } from '@angular/core';
import { TyperService } from 'src/app/services/typer.service';

@Component({
  selector: 'app-typer',
  templateUrl: './typer.component.html',
  styleUrls: ['./typer.component.scss']
})
export class TyperComponent implements OnInit {

  loading!: boolean;

  constructor(private loaderService: TyperService) {

    this.loading = true;
    /* this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    }); */

  }
  ngOnInit() {
  }


}

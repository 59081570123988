<div class="chat-wrapper">
    <div class="custom-header">
      <i [style.background]="'url(../../assets/' + chatbotIcon + ') no-repeat 0 0'" class="header-icon"></i>
      <h3 class="header-title">
        <strong class="chatbot-name">{{'chatbotname' | translate}}</strong>{{'virtualassistant' | translate}}
      </h3>
    </div>

    <nb-chat #chatComponent noMessagesPlaceholder="{{'nomessagesyet' | translate}}">
      <div *nbCustomMessage="'html'; let data">
        <app-typer *ngIf="!data"></app-typer>

        <div *ngIf="data">

          <mat-card *ngIf="data.nomeFile" class="card">
            <mat-card-header>
              <div mat-card-avatar class="header-image">
                <img mat-card-image src="{{data.stream}}?width=150">
              </div>
              <mat-card-title>
                <p [innerHTML]="data.nomeFile" class="cardText"></p>
              </mat-card-title>
            </mat-card-header>
            <!--
            <mat-card-content>
              <p [innerHTML]="data.details" class="cardText"></p>
            </mat-card-content> -->
            <mat-card-actions>
              <button data-suggestion mat-button (click)="suggestionClicked(data.link)">Dettagli</button>
            </mat-card-actions>
          </mat-card>
          <div *ngIf="!data.nomeFile" [innerHTML]="data"></div>

        </div>

      </div>

      <div *nbCustomMessage="'button'; let data">
        <p style="color:white;"> {{data.title}} </p>
        <button data-suggestion *ngFor="let text of data.suggestionText" (click)="suggestionClicked(text)"> {{text}}
        </button>
      </div>

      <nb-chat-message *ngFor="let msg of messages"
                      [type]="msg.type"
                      [message]="msg.text"
                      [reply]="msg.reply"
                      [sender]="msg.sender"
                      [date]="msg.date"
                      [avatar]="msg.avatar"
                      [customMessageData]="msg.customMessageData">
      </nb-chat-message>

      <nb-chat-form (send)="handleUserMessage($event)" messagePlaceholder="{{'askme' | translate}}" [dropFiles]="false"></nb-chat-form>
    </nb-chat>
</div>

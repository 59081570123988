<nb-chat #chatComponent title="Nebular Conversational UI" size="large" [scrollBottom]="false">

  <a *nbCustomMessage="'link'; let data" [href]="data.href" target="_blank">
    {{ data.text }}
  </a>

  <div *nbCustomMessage="'button'; let buttonText">
    <p class="text-control button-custom-message-text">Wow! Button in a message!</p>
    <button nbButton status="control" (click)="chatComponent.scrollListBottom()">
      {{ buttonText }}
    </button>
  </div>

  <nb-custom-message-table *nbCustomMessage="'table'; noStyles: true; let tableData; let isReply=isReply"
                           [columns]="tableData.columns"
                           [rows]="tableData.rows"
                           [isReply]="isReply">
  </nb-custom-message-table>

  <nb-chat-message *ngFor="let msg of messages"
    [type]="msg.type"
    [message]="msg.text"
    [reply]="msg.reply"
    [sender]="msg.user.name"
    [date]="msg.date"
    [customMessageData]="msg.customMessageData">
  </nb-chat-message>

  <nb-chat-form (send)="sendMessage($event)" [dropFiles]="false">
  </nb-chat-form>

</nb-chat>

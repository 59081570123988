import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { DialogflowService } from '../services/dialogflow.service';
import { v4 as uuid } from 'uuid';
import { DFResponse } from '../models/dfresponse';
import { AudioRecorderService } from '../services/audio-recorder.service';
import { ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { filter, skip, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

const CUSTOMIZATION = {
  welcome_tag: '##welcome##',
  welcome_message: 'Welcome to Alecsandria Chatbot, what can I do for you?',      // first chat message
  speech_to_text_error: "Sorry, I didn't get that",                               // message to show if speech to text didn't understand
  bot: {
    name: "Bot",
    avatar: undefined,
    //avatar: "/assets/alecsandria_logo.png",
    reply: false
  },
  user: {
    name: "You",
    avatar: undefined,
    reply: true
  }
};

const ENV_CONFIGS = {
  default: {
    icon: "mod_hd_icon.png",
    styles: {
      "--message-row-color": "#CA1E79",
      "--header-color": "#CA1E79",
      "--reply-bubble-color": "#CA1E79"
    }
  },
  recruit: {
    icon: "recruit_mod_hc_icon.png",
    styles: {
      "--message-row-color": "#0078D7",
      "--header-color": "#02376E",
      "--reply-bubble-color": "#02376E"
    }
  },
  mya: {
    icon: "recruit_mod_hc_icon.png",
    styles: {
      "--message-row-color": "#0078D7",
      "--header-color": "#02376E",
      "--reply-bubble-color": "#02376E"
    }
  },
  "mya-pharma": {
    icon: "recruit_mod_hc_icon.png",
    styles: {
      "--message-row-color": "#61B8C6",
      "--header-color": "#61B8C6",
      "--reply-bubble-color": "#61B8C6"
    }
  },
  loggedportal: {
    icon: "mod_hd_icon.png",
    styles: {
      "--message-row-color": "#AFB4DB",
      "--header-color": "#AFB4DB",
      "--reply-bubble-color": "#AFB4DB"
    }
  },
  notloggedportal: {
    icon: "mod_hd_icon.png",
    styles: {
      "--message-row-color": "#AFB4DB",
      "--header-color": "#AFB4DB",
      "--reply-bubble-color": "#AFB4DB"
    }
  },
  plus: {
    icon: "mod_hd_icon.png",
    styles: {
      "--message-row-color": "#AFB4DB",
      "--header-color": "#AFB4DB",
      "--reply-bubble-color": "#AFB4DB"
    }
  }
};

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatbotComponent implements OnInit {

  messages: any[] = [];
  sessionId = uuid();
  language: string = 'en';
  env: string = 'default';
  piva: '';
  idmin: '';
  intentCalled: string;
  chatbotIcon: string = ENV_CONFIGS.default.icon;
  isLoading: boolean = false;
  typer: any;
  lastHtmlMessage: any;

  constructor(private dialogFlowService: DialogflowService,
    private audioRecorderService: AudioRecorderService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private elRef: ElementRef,
    private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(skip(1)).pipe(take(1))
      .subscribe(params => {
        if (params.lang) {
          this.translate.use(params.lang);
          this.language = params.lang;
        } else {
          this.translate.use('en');
        }

        this.env = (params.env) ? params.env : 'default';
        this.piva = (params.piva) ? params.piva : '';
        this.idmin = (params.idmin) ? params.idmin : 0;

        this.setEnvironmentStyles();
        this.sendToDialogFlow(CUSTOMIZATION.welcome_tag);
      }
    );

    this.audioRecorderService.getRecordedBlob().subscribe(recordedAudioOut => {
      const reader = new FileReader();
      reader.readAsDataURL(recordedAudioOut.blob);
      reader.onloadend = () => {
        let base64 = reader.result as string;
        base64 = base64.split(',')[1];

        this.dialogFlowService.getTextFromSpeech(base64).subscribe(speechToTextResp => {
          this.addTextMessage(speechToTextResp.transcript, false);
        });
      };
    });
  }

  ngAfterViewChecked() {

    let buttons = document.querySelectorAll('[data-suggestion]');
    buttons.forEach(button => {

      if (button.parentElement.parentElement.style.backgroundColor === "" && button.parentElement.parentElement.classList.contains('nb-custom-message')){

        button.parentElement.parentElement.setAttribute('style', 'background: #fff !important; padding: 0 1.25rem !important; margin-top: -25px !important;');
      }

    });

    // Gestione contenuti dei messaggi custom html che possono essere: 1. app typer 2. slider 3. anchor
    try {
      document.querySelectorAll('[ng-reflect-type="html"]').forEach(htmlMessage => {

        this.typer = htmlMessage.querySelector('app-typer');
        this.lastHtmlMessage = document.querySelectorAll('[ng-reflect-type="html"]').item(document.querySelectorAll('[ng-reflect-type="html"]').length - 1);

        // GESTIONE TYPER
        if (this.isLoading) {
          let lastTyper = this.lastHtmlMessage.querySelector('app-typer');
          //lastTyper.removeAttribute('hidden');
          lastTyper.parentElement.parentElement.setAttribute('style', 'background: #fff !important;');
        } else if (!this.isLoading && this.typer){
          if (!htmlMessage.querySelector('[data-custom-message-container]')) {
            htmlMessage.remove();
          }
        }

      });
    }catch (error){
      console.error(error);
    }
  }


  async handleUserMessage(event: { message: string; }) {
    this.addTextMessage(event.message, false);
    this.isLoading = true;
    this.addHtmlMessage(undefined, true);
    await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
      this.sendToDialogFlow(event.message);
    });
  }

  recordingTrigger() {
    console.log((this.audioRecorderService.isRecording) ? 'Stop recording' : 'Recording');
    this.audioRecorderService.toggleRecording();
  }

  private sendToDialogFlow(message: string) {
    this.dialogFlowService.send(message, this.sessionId, this.language, this.env, this.piva, this.idmin).subscribe(response => {
      if(!response.error){
        this.intentCalled = response.intentCalled;
        this.replaceTags(response).forEach(msg => {
          switch (msg.type) {
            case 'text':
              this.addTextMessage(msg.text, true);
              break;
            case 'html':
              this.addHtmlMessage(msg.text, true);
              break;
            case 'suggestion':
              this.addSuggestionsText(response).forEach(suggestionText => {
                this.addButtonMessage({ suggestionText: suggestionText.text, title: msg.text }, true);
              });
              break;
            case 'stream':

              if(this.intentCalled === 'FamigliaProdotto'){
                msg.stream.forEach((document : any) => {
                  this.addHtmlMessage(document, true);
                });

              }else{
                this.downloadStream(msg.stream[0]);
              }

              break;
          }
        })
      }else if (response.error === 'USER_UNAUTHORIZED'){
        window.alert('UTENTE NON AUTORIZZATO');
      }
      this.isLoading = false;
    });
  }

  async suggestionClicked(val:any) {

    if (this.intentCalled === 'FamigliaProdotto') {
      window.open(val);
    }else{
      this.isLoading = true;
      this.addHtmlMessage(undefined, true);
      await new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
        this.sendToDialogFlow('##' + val + '##');
      });
    }
  }

  private replaceTags(dfResponse: DFResponse) {
    let resultMessages = [];

    dfResponse.textMessages.forEach(txtMsg => {
      let type = "text";

      dfResponse.tags.forEach(tag => {

        const linkTarget = tag.openInNewTab ? 'target="_blank"' : '';
        const linkTag = '<a href="' + tag.href + '"' + linkTarget + '>' + tag.label + '</a>';
        const tagPlaceholder = '{!' + tag.tag + '}';

        txtMsg = txtMsg.replace(tagPlaceholder, linkTag);
        type = 'html';
      });
      console.log({
        type: type,
        text: txtMsg
      });
      resultMessages.push({
        type: type,
        text: txtMsg
      });
    });

    if (dfResponse.suggestions.length > 0) {
      resultMessages.push({
        type: "suggestion",
        text: undefined
      });

    }

    if (dfResponse.documents.length > 0){

      resultMessages.push({
        type: "stream",
        stream: dfResponse.documents
      });
    }

    return resultMessages;
  }

  private addHtmlMessage(html: string, fromBot: boolean) {

    const message = {
      type: 'html',
      customMessageData: html
    };

    this.addGenericMessageAttrs(message, fromBot);

    this.messages.push(message);
  }

  private addTextMessage(text: string, fromBot: boolean) {
    const message = {
      text: text,
      type: 'text'
    };

    this.addGenericMessageAttrs(message, fromBot);

    this.messages.push(message);
  }

  private addSuggestionsText(dfResponse: DFResponse) {

    let resultMessages = [];

    resultMessages.push({
      type: "button",
      text: dfResponse.suggestions
    });

    return resultMessages;

  }

  private downloadStream(file: any) {
    try{
      const linkSource = `data: application/pdf;base64,${file.stream}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);

      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = file.nomeFile;
      downloadLink.click();
    }catch(error){
      console.error(error);

    }
  }


  private addButtonMessage(suggestion: any, fromBot: boolean) {

    const message = {
      type: 'button',
      customMessageData: suggestion
    }

    this.addGenericMessageAttrs(message, fromBot);

    this.messages.push(message);

  }

  private addGenericMessageAttrs(message: any, fromBot: boolean) {
    const msgConf = (fromBot) ? CUSTOMIZATION.bot : CUSTOMIZATION.user;

    //message['sender'] = msgConf.name;
    message['avatar'] = msgConf.avatar;
    message['reply'] = msgConf.reply;
    //message['date'] = new Date();
  }

  private setEnvironmentStyles() {
    const config = (ENV_CONFIGS[this.env]) ? ENV_CONFIGS[this.env] : ENV_CONFIGS['default'];

    this.chatbotIcon = config.icon;

    for (const [key, value] of Object.entries(config.styles)) {
      this.elRef.nativeElement.style.setProperty(key, value);
    }
  }

}
